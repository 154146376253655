import { put, takeLatest } from 'redux-saga/effects';
import { paymentTypes, paymentCreators } from './reducer';
import {
  recordErrorInCrashalytics,
  logToCrashalytics,
  NOTIFICATION_TYPE,
  purchaseSubscriptionOrClassPass,
  isClassPass
} from 'tatvarnsdk/Web';
import { showToast } from '@modules/common/index';
const { PAY_FOR_PRODUCT } = paymentTypes;
const { secretkeySuccess, secretkeyFail, paymentSuccess } = paymentCreators;

export function* payForProduct(props) {
  logToCrashalytics('payForProduct => app/scenes/purchaseScreen/saga');
  const { price, cardDetails, subscriptionData, coupon, paymentMethodId, product } = props;
  try {
    const month = cardDetails ? parseInt(cardDetails.month) : null;
    const year = cardDetails ? cardDetails.year : null;
    const discipline = product.discipline;
    const newPrice = isClassPass(product) ? price : product.unit_amount;
    const response = yield purchaseSubscriptionOrClassPass(
      cardDetails ? cardDetails.cardNumber : null,
      month,
      year,
      cardDetails ? cardDetails.cvv : null,
      cardDetails ? cardDetails.name : null,
      paymentMethodId,
      subscriptionData,
      newPrice,
      coupon,
      { discipline: discipline ? discipline.id : null }
    );
    if (!response || !response.success || !response.data) {
      throw response?.error ?? 'Error Deleting Card';
    } else if (response.data.clientSecret) {
      yield put(secretkeySuccess(response.data.clientSecret, response.data.paymentMethodId));
    } else {
      yield put(paymentSuccess(true));
    }
  } catch (e) {
    showToast({
      titleId: 'OOPS!',
      message: e,
      notificationType: NOTIFICATION_TYPE.ERROR
    });
    recordErrorInCrashalytics('my subscription saga payForProduct', e);
    yield put(secretkeyFail(e));
  }
}

export default function* subscriptionSaga() {
  yield takeLatest(PAY_FOR_PRODUCT, payForProduct);
}
