import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';
import { logToCrashalytics, recordErrorInCrashalytics } from 'tatvarnsdk/Web';

const USE_FIREBASE_EMULATOR = false;

const firebaseConfig = require('./firebase-config.json').result.sdkConfig;
export const firebaseApp = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(firebaseApp);
export const functions = getFunctions(firebaseApp, 'europe-west2');

const API_VERSION = 'v2';
const newMethods = [];

export const triggerCloudFunction = async (methodName, data) => {
  const functionName = newMethods.includes(methodName) ? `${methodName}_${API_VERSION}` : methodName;
  logToCrashalytics(`Cloud Function => ${functionName}`);
  try {
    if (USE_FIREBASE_EMULATOR) {
      connectFunctionsEmulator(functions, '127.0.0.1', 9001);
    }
    // console.log('Parameters:', data);
    const cloudFunction = httpsCallable(functions, functionName);
    return await cloudFunction(data)
      .then((result) => {
        // console.log('Result', result.data);
        return result.data;
      })
      .catch((error) => {
        throw error.message ?? error;
      });
  } catch (e) {
    recordErrorInCrashalytics(`Error in calling cloudfunction ${functionName}`, e);
    return null;
  }
};
